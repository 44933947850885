.form-group {
  margin-bottom: .5rem !important;
}

.gov-co-form-group.gov-co-error > div > textarea {
  border: 1px solid #a80521 !important;
}

.gov-co-form-group.gov-co-error > textarea {
  border: 1px solid #a80521 !important;
}
