/* You can add global styles to this file, and also import other style files */
// Global styles ... assets/styles

// ngx-quill
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

@import "./assets/styles/message-errors.scss";
@import "./assets/styles/titles.scss";
@import "./assets/styles/mat-menu.scss";
@import "./assets/styles/selectors.scss";
@import "./assets/styles/forms.scss";
@import "./assets/styles/mat-dialog.scss";
@import "./assets/styles/spinner.scss";
@import "./assets/styles/table.scss";
@import "./assets/styles/popup.scss";
@import "./assets/styles/pagination.scss";
@import "./assets/styles/fade-in-view.scss";
@import "assets/styles/custom-buttons";
@import "./assets/styles/quill-editor.scss";

// @import "~ng-zorro-antd/ng-zorro-antd.min.css";
// @import "~ng-zorro-antd/style/index.min.css";

@import "~@ng-select/ng-select/themes/default.theme.css";

// App colors
:root {
  --popupBackground: #3366CC;

  --blue-base-components: #E5EEFB;
  --blue-typography: #004884;
  --gray-typography: #4b4b4b;

  --gray-color-400: #F6F6F6;
  --blue-color-300: #93c5fd;
  --blue-color-400: #60a5fa;
  --blue-color-500: #3b82f6;
  --blue-color-600: #2563eb;
  --blue-color-700: #1d4ed8;
  --blue-color-800: #1e40af;
  --blue-color-900: #1e3a8a;
  --message-errors: #DC3545;
  --primary-color-300: #3366CC;
  --primary-color-600: #004884;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

input.gov-co-form-control {
  &:focus {
    box-shadow: 0 0 5px #36c !important;
  }
}

.inactiveLink {
  pointer-events: none;
  cursor: default;
}

// Faltante CDN
.govco-icon-dropdown-v2::before {
  content: "\e826";
}

.myScroll::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

html app-signin {
  scrollbar-width: none; /* Firefox */
}

.table-responsive {
  overflow-y: hidden;
}

/*.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}*/
input.form-control:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

::ng-deep {
  .gov-co-form-group > div > select:focus, .gov-co-form-group > select:focus, .gov-co-form-group textarea:focus {
    box-shadow: 0 0 5px #3366cc;
  }

  select.form-control:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 5px #36c !important;
  }

  select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 5px #36c !important;
  }

  textarea.form-control:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 5px #36c !important;
  }

  .form-control:disabled:focus, .form-control[readonly]:focus {
    background-color: #e9ecef;
    opacity: 1;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 5px #36c !important;
  }
}

select.form-control:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 5px #36c !important;
}

select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 5px #36c !important;
}

textarea.form-control:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 5px #36c !important;
}

.hover-custom-row-msab {
  transition: all 0.5s ease-out;

  &:hover {
    background: #E6EFFD;
    color: #004884;
    cursor: pointer;
  }
}

.select-custom-row-menu-msab {
  color: #E6EFFD;
}

.select-custom-row-msab {
  background: #004884;
  color: #E6EFFD !important;
  cursor: pointer;
}

.select-custom-row-msab  span {
  background: #004884;
  color: #E6EFFD !important;
  cursor: pointer;
}

.govco-icon-close::before, .govco-icon-tramite-error::before {
  content: "\e803";
}

$color_rd: #a80521;
.gov-co-form-group.gov-co-error {
  select {
    border: 1px solid $color_rd !important;
  }
  input {
    border: 1px solid $color_rd !important;
  }
  .input-group-append {
    >button {
      border: 1px solid $color_rd !important;
    }
  }
  .ql-container {
    border: 1px solid $color_rd !important;
  }
}

$color_1: #ddfb00;
.body-cs {
  .bdc-custom {
    * {
      background: black;
      color: $color_1 !important;
    }
    select {
      color: $color_1 !important;
      background: black !important;
    }
    textarea {
      color: $color_1 !important;
      background: black !important;
    }
    input {
      color: $color_1 !important;
      background: black !important;
    }
  }
}

.g-error {
  color: $color_rd!important;
  .gj-datepicker {
    border: 1px solid $color_rd;
  }
  label {
    color: $color_rd!important;
  }
}

.inactive-global {
  pointer-events: none;
  cursor: default;
}

.inactive-global .form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.not-available {
  pointer-events: none;
  opacity: 0.5;
}

.spinner-border {
  color: #FFFFFF;
}
